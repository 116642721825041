<template>
  <div id="style-atoms">
    <h1>Im am a h1 heading</h1>
    <h2>Im am a h2 heading</h2>
    <h3>Im am a h3 heading</h3>
    <h4>Im am a h4 heading</h4>
    <hr />

    <p>I am a paragraph with a <strong>strong</strong> word.</p>
    <p>I am a paragraph with a <i>italic</i> word.</p>
    <p>I am a paragraph with a <b>bold</b> word.</p>
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
      sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
      ipsum dolor sit amet.
    </p>
    <hr />

    <ol>
      <li>Ordered list</li>
    </ol>

    <ul>
      <li>Unordered list</li>
    </ul>
    <hr />

    <v-card>I am a card</v-card>
    <hr />

    <!-- banners -->
    <Banner type="info" text="This is an info banner" />
    <Banner type="success" text="This is an success banner" />
    <Banner type="error" text="This is an error banner" />
    <Banner type="warning" text="This is an warning banner" />
    <hr />

    <!-- buttons -->
    <v-btn>Normal button</v-btn>
    <v-btn color="primary">Primary color button</v-btn>
    <v-btn color="secondary">Secondary color button</v-btn>
    <hr />

    <!-- snackbars -->

    <!-- datatable -->

    <!-- form elements -->
    <v-text-field value="Input field"></v-text-field>
    <v-textarea value="Textarea"></v-textarea>
    <v-select :items="selectItems" label="Selectbox" />
    <v-radio-group v-model="radioGroup" label="Radiongroup">
      <v-radio value="item1" label="item1" />
      <v-radio value="item2" label="item2" />
      <v-radio value="item3" label="item3" />
    </v-radio-group>
    <v-checkbox label="Advanced Search" value="Advanced Search"></v-checkbox>
    <v-checkbox label="ShotTimeCache" value="Short Time Cache"></v-checkbox>
    <hr />

    <!-- snackbars -->
    <v-btn @click.prevent="showErrorSnackMessage">Show error snackmessage</v-btn>
    <v-btn @click.prevent="showSuccessSnackMessage">Show success snackmessage</v-btn>
    <hr />

    <!-- tooltips -->
    <Tooltip description="This is a test icon tooltip" icon="mdi-bullhorn-outline" /> <br />
    <Tooltip description="This is a test text tooltip" text="Tooltip text only" /> <br />
    <Tooltip description="This is a test text and icon tooltip" text="Tooltip with icon" icon="mdi-bullhorn-outline" />
    <hr />

    <!-- datatable compact view -->

    <!-- expando -->
    <v-expansion-panels accordion>
      <v-expansion-panel id="expando1">
        <v-expansion-panel-header>Expando heading 1</v-expansion-panel-header>
        <v-expansion-panel-content>Expando content 1</v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="expando2">
        <v-expansion-panel-header>Expando heading 2</v-expansion-panel-header>
        <v-expansion-panel-content>Expando content 2</v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Banner from '../../components/others/Banner'
import Tooltip from '../../components/others/ToolTip'

export default {
  components: {
    Banner,
    Tooltip
  },
  data() {
    return {
      selectItems: ['Item 1', 'Item 2', 'Item 3'],
      radioGroup: null
    }
  },
  methods: {
    showErrorSnackMessage() {
      this.$store.dispatch('snackbar/showErrorMessage', { message: 'test error' })
    },
    showSuccessSnackMessage() {
      this.$store.dispatch('snackbar/showSuccessMessage', { message: 'test success' })
    }
  }
}
</script>
