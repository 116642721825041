<template>
  <v-tooltip :top="this.top" :bottom="this.bottom" :left="this.left" :right="this.right" :contentClass="this.customClasses">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-if="icon.length > 0" v-bind="attrs" v-on="on" :class="iconClasses" :size="iconSize" :color="iconColor">
        {{ icon }}
      </v-icon>
      <span v-bind="attrs" v-on="on">{{ text }}</span>
    </template>
    <span v-html="this.description"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    description: String,
    customClass: {
      String,
      default: ''
    },
    icon: {
      String,
      default: ''
    },
    text: {
      String,
      default: ''
    },
    iconSize: {
      String,
      default: undefined
    },
    iconColor: {
      String,
      default: ''
    },
    iconClass: {
      String,
      default: ''
    },
    placement: {
      String,
      default: 'bottom',
      validator: propValue => {
        return ['bottom', 'top', 'right', 'left'].includes(propValue)
      }
    }
  },
  computed: {
    iconClasses() {
      return `tooltip-icon ${this.iconClass}`
    },
    customClasses() {
      return `tooltip ${this.customClass}`
    },
    bottom() {
      return this.placement === 'bottom'
    },
    top() {
      return this.placement === 'top'
    },
    left() {
      return this.placement === 'left'
    },
    right() {
      return this.placement === 'right'
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .tooltip
  box-shadow: 0 5px 16px rgb(0 0 0 / 10%)
  background: $sheet-background
  color: $secondary
  max-width: 250px
  opacity: 1 !important

#app .tooltip.tooltip-wide
  max-width: 320px
</style>
